import React, { useState } from "react";
import "./../../styles/App.scss";
import Button from "../Button";
import Footer from "./../Footer";
import logo from "./../../img/LLOM_logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from 'react-router-dom';
import MaterialIcon from 'material-icons-react';

function LoginForm(props) {
  const [username, setUsername] = useState(null);
  const [pass, setPass] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = value => {
    setCaptcha(value);
  };

  const submitData = async event => {
    event.preventDefault();
    if(captcha == null) {
      setErrorMessage("You did not check the CAPTCHA");
    } else if(username == null || pass == null) {
      setErrorMessage("You did not fill all the necessary fields");
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("withCredentials", true);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Expose-Headers", "*");
      
      var raw = JSON.stringify({
        name: String(username), 
        pass: String(pass)
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        credentials: "include",
      };

      await fetch(
        process.env.REACT_APP_LLOM_API_HOST +"/user/login?_format=json",
        requestOptions
      )
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        if (JSON.parse(result).current_user){
          window.localStorage.setItem("user-info", result);
          window.location.replace("/registration-landing");
        } else {
          setErrorMessage("An error occured. "+JSON.parse(result).message);
        }
      })
      .catch((error) => console.log("error", error));
    }
  }

  return (
    <div className="intro">
      <span style={{cursor:"pointer",left:"0",top:"10px",position:"absolute"}} onClick={() => props.history.goBack()}>
        <MaterialIcon icon="arrow_left" size="50"/>
      </span>
      <span style={{cursor:"pointer",right:"0",top:"10px",position:'absolute'}} onClick={() => props.history.goForward()}>
        <MaterialIcon icon="arrow_right" size="50"/>
      </span>
      
      <Link to="/">
        <img className="logo-front" src={logo} alt="logo" />
      </Link>
      <h1 className="motto">Log In</h1>
      <div className="line-green" />
      { errorMessage &&
        <div className="col-6 mx-auto mt-4">
          <div className="alert alert-danger" role="alert">
            { errorMessage }
          </div>
        </div> 
      }
      <div className="space-around">
        <div className="text-label">User Name</div>
        <input
          onChange={(e) => setUsername(e.target.value)}
          className="login-form"
        />
      </div>
      <div className="space-around">
        <div className="text-label">Password</div>
        <input
          onChange={(e) => setPass(e.target.value)}
          className="login-form"
          type="password"
        />
      </div>
      <div className="space-around">
        <ReCAPTCHA
          style={{ display: "inline-block" }}
          theme="dark"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onChange={(e) => handleChange(e)}
          
        />
        <Button
          background="green"
          text="LOG IN"
          link=""
          onClick={(e) => submitData(e)}
        ></Button>
        
        {/*<div
          className="text-label reset-pass">
          Forgot your password?
        </div>

        <Button
          background="blue"
          text="RESET PASSWORD"
          link="/reset-password">
        </Button>*/}

        <div
          className="text-label reset-pass">
          <Link to="/reset-password">Forgot your password?</Link>
        </div>
        
        <Footer />

      </div>
    </div>
  );
}

export default LoginForm;