import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const Button = (props) => {
  const styleGreen = {
    background: "rgba(23, 140, 56, 0.80)",
  };
  const styleYellow = {
    background: "rgba(228, 194, 43, 0.82)",
  };
  const styleBlue = {
    background: "rgba(40, 71, 133, 0.68)",
  };
  const styleGrey = {
    background: "#C4C4C4",
  };
  const switchStyle = (x, y, z, w) => {
    switch (props.background) {
      case "green":
        return x;
      case "yellow":
        return y;
      case "blue":
        return z;
      case "grey":
        return w;
      default:
        return x;
    }
  };

  return (
    <Link style={{ textDecoration: "none" }} to={props.link}>
      <div
        className="button"
        type="button"
        onClick={props.onClick}
        style={switchStyle(styleGreen, styleYellow, styleBlue, styleGrey)}
      >
        <div className="button-text">{props.text}</div>
      </div>
    </Link>
  );
};

export default Button;

Button.propTypes = {
	/**
	 * Button backgound color.
	 */
	background: PropTypes.oneOf(['blue','yellow','green','grey']).isRequired,
  /**
	 * Button link.
	 */
	link: PropTypes.string,
  /**
	 * Button text.
	 */
  text: PropTypes.string.isRequired,
  /**
	 * Button onClick event handler function.
	 */
  onClick: PropTypes.func
};