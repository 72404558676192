import React from "react";
import { Link } from "react-router-dom";
import MaterialIcon from 'material-icons-react'
import PropTypes from 'prop-types'

function CircularButtons(props) {
  return (
    <div className="d-flex justify-content-center p-2">
      <Link to="/registration-landing">
        <img
          className="circular-button link lang-icon"
          src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/"+window.localStorage.getItem("code") +".svg"}
          alt={window.localStorage.getItem("code")}
        />
      </Link>
      <Link className="link" to="/registration-landing">
        <h4 className="circular-button" style={{paddingTop:"8px"}}>
          {window.localStorage.getItem("level")}
        </h4>
      </Link>
      <h4 className="circular-button" style={{paddingTop:"9px"}}>
        {props.topic === "?" ? <>?</>:<div style={{paddingTop:"2px"}}><MaterialIcon icon={props.topic}/></div>}
      </h4>
    </div>
  );
}
export default CircularButtons;

CircularButtons.propTypes = {
	/**
	 * Topic of the assignment.
	 */
	topic: PropTypes.oneOf(['?','Material icon classname']).isRequired,
  
};