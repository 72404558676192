import React from "react";
import "./../styles/App.scss";
import { Link } from "react-router-dom";

function NotFoundPage() {
    
    return (
      	<div className="error center-screen">
      		<h1 className="text-danger">Oops! 404 PAGE NOT FOUND</h1>
      		<p style={{textAlign:"center"}}>
              	<Link to="/">Go to Home </Link>
            </p>  
      </div>
    );
}

export default NotFoundPage;