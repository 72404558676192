import React, { useEffect, useState } from "react";
import "./../../styles/App.scss";
import { Link } from "react-router-dom";
import Button from "../Button";
import Header from './../Header';
import Footer from './../Footer';
import arch from "./../../img/vectorArch.svg";
import Menu from "../Menu";
import Loader from "react-loader-spinner";

function Profile(props) {
  const [data, setData] = useState([]);
  const [lang, setLang] = useState([]);
  const [name, setName] = useState("");
  const [created, setCreated] = useState("");
  //const [id, setId] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-CSRF-Token",
      String(
        JSON.parse(window.localStorage.getItem("user-info"))
          .csrf_token
      )
    );
    myHeaders.append(
      "Accept", "application/json"
    );
    myHeaders.append(
      "Content-Type", "application/json"
    );
    myHeaders.append(
      "Access-Control-Allow-Origin", "*"
    );

    var requestOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": String(
          JSON.parse(window.localStorage.getItem("user-info")).csrf_token
        )
      },
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      process.env.REACT_APP_LLOM_API_HOST +
        "/user/" +
        JSON.parse(window.localStorage.getItem("user-info")).current_user.uid +
        "?_format=json",
      requestOptions
    )
    .then((response) => response.json())
    .then((result) => {
      if(result.name != null){
        setData(result);
        setName(result.name[0].value);
        setCreated(result.created[0].value);
        setEmail(result.mail[0].value);
      }
      //setId(result.uid[0].value);
    })
    .catch((error) => console.log("error", error));

    fetch(
      process.env.REACT_APP_LLOM_API_HOST +
        "/rest-user/" +
        JSON.parse(window.localStorage.getItem("user-info")).current_user.uid +
        "/languages",
      requestOptions
    )
    .then((response) => response.json())
    .then((result) => {
      if(result.message !== "") {
        setLang(result)
      }
    })
    .catch((error) => console.log("error", error));
  });

  if (data != null) {
    return (
      <div className="App">
        <Header history={props.history} title_above="Profile"/>

        <div className="space-around">
          <h4 className="text-label">Username</h4>
          <h1 className="motto">{name}</h1>
        </div>
        <div className="space-around">
          <h4 className="text-label">Member since</h4>
          <h1 className="motto">{created}</h1>

          <div>
            <div className="space-around">
              <h4 className="text-label">E-mail</h4>
              <h1 className="motto">{email}</h1>
            </div>
            {/*<div className="space-around">
                <h4 className="text-label">Some ID</h4>
                <h1 className="motto">{id}</h1>
            </div>*/}

            <h4 className="text-label">Languages</h4>
            <div className="d-flex justify-content-center p-2">
              {lang.length === 0 ? (
                <h1 className="motto">No Results</h1>
              ) : (
                lang.map((i) => (
                  <img
                    className="circular-button"
                    src={
                      "http://purecatamphetamine.github.io/country-flag-icons/3x2/" +
                      i.field_country_code +
                      ".svg"
                    }
                    alt={i.field_country_code}
                  />
                ))
              )}
            </div>

            <Link to="/achievements">
              <div className="link space-around">
                <h1 className="motto d-inline">Achievements</h1>
                <img
                  className="d-inline"
                  style={{ marginLeft: "150px" }}
                  src={arch}
                  alt="arch"
                />
              </div>
            </Link>
            <div className="d-flex justify-content-center p-2">
              <Button
                text="EDIT PROFILE"
                background="blue"
                link="/edit"
              ></Button>
            </div>
          </div>
        </div>

        <div>
          <Menu />
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Loader
          className="spinner"
          type="TailSpin"
          color="#3986df"
          height={70}
          width={70}
        />
      </div>
    );
  }
}

export default Profile;
