import React, { useState } from "react";
import "./../../styles/App.scss";
import Button from "../Button";
import Footer from "./../Footer";
import logo from "./../../img/LLOM_logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from 'react-router-dom'
import MaterialIcon from 'material-icons-react'

function Register(props) {
  const [username, setUsername] = useState();
  const [pass, setPass] = useState();
  const [email, setEmail] = useState();
  const [consent, setConsent] = useState(false);
  const [match, setMatch] = useState();
  //const [institution, setInstitution] = useState();
  const [captcha,setCaptcha]=useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  /*const history = useHistory();*/

  const handleChange = value => {
    setCaptcha(value);
  };

  const submitData = event => {
    if(captcha == null) {
      setErrorMessage("You did not check CAPTCHA")
    } else if(!consent) {
      setErrorMessage("You did not check the consent checkbox")
    } else if(!email || !username) {
      setErrorMessage("You did not provide all necessary information")
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: [{ value: String(username) }],
        mail: [{ value: String(email) }],
        pass: [{ value: String(pass) }],
        //field_institution: [{ value: institution }],
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_LLOM_API_HOST +"/user/register?_format=json",
        requestOptions
      )
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).message) {
          setErrorMessage("An error occured. "+JSON.parse(result).message);
        } else {
          setErrorMessage("");
          setSuccessMessage("Your registration is successfully completed.")
        }
        
      })
      .catch((error) => console.log("error", error));
    }
  }

  return (
    <div className="register">
      <span style={{cursor:"pointer",left:"0",top:"10px",position:"absolute"}} onClick={() =>props.history.goBack()}><MaterialIcon icon="arrow_left" size="50"/></span>
      <span style={{cursor:"pointer",right:"0",top:"10px",position:'absolute'}}onClick={() =>props.history.goForward()}><MaterialIcon icon="arrow_right" size="50"/></span>
      <span>
        <div style={{ display: "inline-block" }}>
          <h1 className="motto" style={{ paddingRight: "50px" }}>
            Register
          </h1>
          <div className="line-yellow" />
        </div>
        <Link to="/">
          <img
            style={{ display: "inline-block" }}
            className="logo-front1"
            src={logo}
            alt="logo"
          />
        </Link>
      </span>
      { errorMessage &&
        <div className="col-6 mx-auto mt-4">
          <div className="alert alert-danger" role="alert">
            { errorMessage }
          </div>
        </div> 
      }
      { successMessage &&
        <div className="col-6 mx-auto mt-4">
          <div className="alert alert-success" role="alert">
            { successMessage }
          </div>
        </div> 
      }
      <div className="space-around">
        <div className="text-label">User Name</div>
        <input onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div className="space-around">
        <div className="text-label">E-mail address</div>
        <input onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="space-around">
        <div className="text-label">Password</div>
        <input type="password" onChange={(e) => setPass(e.target.value)} />
      </div>
      <div className="space-around">
        <div className="text-label">Repeat password</div>
        <input
          type="password"
          onChange={(e) => {
            if (e.target.value === pass) setMatch("YES");
            else {
              setMatch("NO");
            }
          }}
        />
        {match === "YES" ? <div style={{color:"green"}}>{match}</div>:<div style={{color:"red"}}>{match}</div>}
      </div>
      {/*<div className="space-around">
        <div className="text-label">Institution</div>
        <input onChange={(e) => setInstitution(e.target.value)} />
        </div>*/}
      <ReCAPTCHA
        style={{ display: "inline-block" }}
        theme="dark"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={(e) => handleChange(e)}
      />
      <div className="form-check mt-3 mb-3">
        <input 
          className="form-check-input" 
          type="checkbox" 
          id="flexCheckDefault" 
          onChange={() => setConsent(!consent)} />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          I have read and understood and agree to the <a href="//cms.llom.eu/terms" rel="noreferrer" target="_blank">Terms of Service</a>
        </label>
      </div>
      
      <Button
        background="yellow"
        text="REGISTER"
        onClick={(e) => submitData(e)}>
      </Button>

      <div
        className="text-label mt-5 mb-3">
        Already have an account?
      </div>

      <Button
        background="blue"
        text="LOGIN"
        link="/login-form">
      </Button>

      <Footer />
    </div>
  );
}

export default Register;