import IframeResizer from "iframe-resizer-react";
import React from "react";
import Header from "./../Header";
import Menu from "../Menu";
import { useLocation } from "react-router-dom";
import CircularButtons from "../CircularButtons";
import { useHistory } from 'react-router-dom';

const Quizes = ({
  match: {
    params: { name },
  },
},props) => {
  const history = useHistory();

  let params = useLocation();
  /*const ref = useRef(null);*/
  /*const [messageData, setMessageData] = useState(undefined);*/

  /*const onResized = (data) => setMessageData(data);*/

  /*const onMessage = (data) => {
    setMessageData(data);
    ref.current.sendMessage("Hello back from parent page");
  };*/
  
  return (
    <div className="App iframe-container">
      <Header
        history={history}
        title_above={
          JSON.parse(window.localStorage.getItem("user-info")).current_user.name
        }
      />

      <CircularButtons topic={params.search.split("=")[1]} />
      <div className="d-flex justify-content-center m-5">
        <IframeResizer
          allow="geolocation; microphone; camera"
          allowfullscreen="true"
          allowtransparency="true"
          className="webform-share-iframe"
          inPageLinks
          frameBorder="0"
          src={
            process.env.REACT_APP_LLOM_API_HOST +
            "/node/" +
            name +
            "/assignment"
          }
          scrolling="no"
          style={{ minWidth: "100%", minHeight: "100%" }}
          heightCalculationMethod="bodyOffset"
        />
      </div>

      <div>
        <Menu />
      </div>
    </div>
  );
};

export default Quizes;