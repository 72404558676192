import React, { useEffect, useState } from "react";
import "./../../styles/App.scss";
import Loader from "react-loader-spinner";
import AchievementsRow from "./AchievementsRow";
import Header from './../Header';
import Footer from './../Footer';
import Menu from "../Menu";
import star from "./../../img/star.png";

function Achievements(props) {

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-CSRF-Token",
      String(
        JSON.parse(window.localStorage.getItem("user-info"))
          .csrf_token
      )
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      process.env.REACT_APP_LLOM_API_HOST +
        "/rest-user/" +
        JSON.parse(window.localStorage.getItem("user-info")).current_user.uid +
        "/points",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message!==""){
          setData(result);
        }
        setLoaded(true);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const renderRows = () => {
    if (loaded&&data.length!==0) {
      return data.map((row) => (
        <div className="d-flex justify-content-center">
        <AchievementsRow
          title={row.title}
          points={row.points}
          max={row.max_points}
          topic={row.field_topic_icon}
          level={row.field_llom_level}
          lang={row.field_country_code}
          finished={row.finished}
        />
        </div>
      ));
    } else {
      return(
        <div className="d-flex justify-content-center">You have not completed any assignments yet. </div>
      )
    }
  };

  const totalPoints = () => {
    let points = 0;
    if (loaded) {
      data.map((row) => (points += parseInt(row.points)));
      return points;
    }
  };

  if (!loaded) {
    return (
      <div className="App">
        <Loader
          className="spinner"
          type="TailSpin"
          color="#3986df"
          height={70}
          width={70}
        />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Header 
          history={props.history} 
          title_below="Achievements" 
          title_above={JSON.parse(window.localStorage.getItem("user-info")).current_user.name}/>
          
        <div className="space-around">
          {renderRows()}
        </div>

        <div>
          <p>Total</p> {totalPoints()} <img src={star} alt="star" />
        </div>

        <div>
          <Menu />
        </div>

        <Footer />
      </div>
    );
  }
}

export default Achievements;