import React from "react";
import "./../../styles/App.scss";
import star from "./../../img/star.png";
import cup from "./../../img/cup.png";
import MaterialIcon from "material-icons-react";
import PropTypes from 'prop-types';


function AchievementsRow(props) {
  return (
    <div>
      <tr>
        <td>
          <img src={cup} alt="cup" />
        </td>
        <td className="text-medium">{props.points}</td>

        <td>
          <img src={star} alt="star" />
        </td>
        <td className="text-medium">in</td>
        <td>
          {props.lang ? (
            <img
              className="circular-small"
              src={
                "http://purecatamphetamine.github.io/country-flag-icons/3x2/" +
                props.lang +
                ".svg"
              }
              alt={props.lang}
            />
          ) : (
            ""
          )}
        </td>
        <td>
          <div className="circular-small">{props.level}</div>
        </td>
        <td>
          <div
            className="circular-small"
            data-toggle="tooltip"
            title={props.topic}
          >
            <MaterialIcon icon={props.topic} size={18} />
          </div>
        </td>
      </tr>
      <tr>
        <div className="text-small">{props.finished}</div>
      </tr>
      <hr></hr>
    </div>
  );
}

export default AchievementsRow;
AchievementsRow.propTypes = {
	/**
	 * The title of the assignment
	 */
  title: PropTypes.string,
  /**
	 * The points the user has achieved by completing the assignment of the row
	 */
  points: PropTypes.string,
   /**
	 * The maximum points of the assignment   
	 */
  max: PropTypes.string,
   /**
	 * The topic of the assignment 
	 */
  topic: PropTypes.string,
  /**
	 * The level of the assignment 
	 */
  level: PropTypes.string,
  /**
	 * The language of the assignment 
	 */
  lang: PropTypes.string,
  /**
	 * The date the assignment was completed
	 */
  finished: PropTypes.string,
	
};