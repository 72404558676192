import React from "react";
import { Switch, Route,Redirect } from "react-router-dom";
import LoginRegisterForgotPw from "./login-register/LoginRegisterForgotPw";
import Profile from "./profile/Profile";
import Game from "./game/Game";
import Quizes from "./game/Quizes";
import LandingPage from "./LandingPage";
import EditProfile from "./profile/EditProfile";
import Achievements from "./achievements/Achievements";
import LoginForm from "./login-register/LoginForm";
import ResetPassword from "./login-register/ResetPassword";
import Register from "./login-register/Register";
import RegistrationLandingPage from "./login-register/RegistrationLandingPage";
import NotFoundPage from './NotFoundPage'
import { ProtectedRoute } from "./ProtectedRoute";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute
          exact
          path="/registration-landing"
          component={RegistrationLandingPage}
        />
        <Route exact path="/intro" component={LoginRegisterForgotPw} />
        <ProtectedRoute exact path="/achievements" component={Achievements} />
        <ProtectedRoute exact path="/login-form" component={LoginForm} />
        <ProtectedRoute exact path="/reset-password" component={ResetPassword} />
        <ProtectedRoute exact path="/register" component={Register} />
        <ProtectedRoute exact path="/" component={LandingPage} />
        <ProtectedRoute path="/game/:name" component={Game} />
        <ProtectedRoute exact path="/edit" component={EditProfile} />
        <ProtectedRoute path="/quiz/:name" component={Quizes}/>
        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default Routes;
