import React, { useState, useEffect } from "react";
import "./../../styles/App.scss";
import Loader from "react-loader-spinner";
import Button from "../Button";
import Header from './../Header';
import Footer from './../Footer';
import Menu from "../Menu";

function EditProfile(props) {
  const [editName, setEditName] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  //const [editID, setEditID] = useState(false);
  const [name, setName] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [email, setEmail] = useState("");
  //const [ID, setID] = useState("");
  const [data, setData] = useState([]);
  const [match, setMatch] = useState();
  const [saveAll,setSaveAll]=useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-CSRF-Token",
      String(
        JSON.parse(window.localStorage.getItem("user-info"))
          .csrf_token
      )
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      process.env.REACT_APP_LLOM_API_HOST +"/user/" +
        JSON.parse(window.localStorage.getItem("user-info")).current_user.uid+"?_format=json",
      requestOptions
    )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result != null){
        setData(result);
        setName(result.name[0].value);
        setEmail(result.mail[0].value);
      }
        //setID(result.uid[0].value);
    })
    .catch((error) => console.log("error", error));
  }, []);

  if(!name) {
    return (
      <div className="App">
        <Loader
          className="spinner"
          type="TailSpin"
          color="#3986df"
          height={70}
          width={70}
        />
      </div>
    );
  } else {
    return (
      <div className="App">
        <div>
          <Header history={props.history} title_above="Edit Profile"/>
          { errorMessage &&
            <div class="col-6 mx-auto mt-4">
              <div class="alert alert-danger" role="alert">
                { errorMessage }
              </div>
            </div> 
          }
          <div className="space-around">
            {editName ? (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">Username</h4>
                  <input
                    className="login-form"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div
                  className="edit-button inline"
                  onClick={() => {
                    setEditName(false);
                    var myHeaders = new Headers();
                    myHeaders.append(
                      "X-CSRF-Token",
                      String(
                        JSON.parse(window.localStorage.getItem("user-info"))
                          .csrf_token
                      )
                    );
                    myHeaders.append("Content-Type", "application/json");
                    
                    var raw = JSON.stringify({
                      name: [{ existing: data.name[0].value, value: name }],
                    });

                    var requestOptions = {
                      method: "PATCH",
                      headers: myHeaders,
                      body: raw,
                      redirect: "follow",
                      credentials: "include",
                    };

                    fetch(
                      process.env.REACT_APP_LLOM_API_HOST +"/user/"+JSON.parse(window.localStorage.getItem("user-info")).current_user.uid+"?_format=json",
                      requestOptions
                    )
                    .then((response) => response.text())
                    .then((result) => {
                      if (JSON.parse(result).message) {
                        setErrorMessage("An error occured");
                      }
                    })
                    .catch((error) => console.log("error", error));
                  }}
                >
                  Save
                </div>
              </div>
            ) : (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">Username</h4>
                  <h1 className="motto">{name}</h1>
                </div>
                <div
                  className="edit-button inline"
                  onClick={() => setEditName(true)}
                >
                  Edit
                </div>
              </div>
            )}

            {editPassword ? (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">Old Password</h4>
                  <input
                    type="password"
                    className="login-form"
                    onChange={(e) => setOldPass(e.target.value)}
                  />
                  <h4 className="text-label">New Password</h4>
                  <input
                    type="password"
                    className="login-form"
                    onChange={(e) => setNewPass(e.target.value)}
                    required
                  />
                  <h4 className="text-label">Repeat Password</h4>
                  <input
                    type="password"
                    className="login-form"
                    onChange={(e) => {if(e.target.value === newPass){
                      setMatch("YES")
                    } else {
                      setMatch("NO")
                    }
                  }}
                    required
                  />
                  {match === "YES" ? <div style={{color:"green"}}>{match}</div>:<div style={{color:"red"}}>{match}</div>}
                </div>
                
                <div
                  className="inline edit-button"
                  onClick={() => {
                    setEditPassword(false);
                    var myHeaders = new Headers();
                    myHeaders.append(
                      "X-CSRF-Token",
                      String(
                        JSON.parse(window.localStorage.getItem("user-info"))
                          .csrf_token
                      )
                    );
                    myHeaders.append("Content-Type", "application/json");
                    var raw = JSON.stringify({
                      pass: [
                        { existing: String(oldPass), value: String(newPass) },
                      ],
                    });

                    var requestOptions = {
                      method: "PATCH",
                      headers: myHeaders,
                      body: raw,
                      redirect: "follow",
                      credentials: "include",
                    };

                    fetch(
                      process.env.REACT_APP_LLOM_API_HOST +"/user/"+JSON.parse(window.localStorage.getItem("user-info")).current_user.uid+"?_format=json",
                      requestOptions
                    )
                      .then((response) => response.text())
                      .then((result) => {
                        if (JSON.parse(result).message) {
                          setErrorMessage("An error occured");
                        }
                      })
                      .catch((error) => console.log("error", error));
                  }}
                >
                  Save
                </div>
              </div>
            ) : (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">Password</h4>
                  <h1 className="motto" type="password">
                    ************
                  </h1>
                </div>
                <div
                  className="inline edit-button"
                  onClick={() => setEditPassword(true)}
                >
                  Edit
                </div>
              </div>
            )}
            {editEmail ? (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">E-mail</h4>
                  <input
                    className="login-form"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <h4 className="text-label">Password</h4>
                  <input
                    className="login-form"
                    onChange={(e) => setOldPass(e.target.value)}
                    type="password"
                  />
                </div>
                <div
                  className="edit-button inline"
                  onClick={() => {
                    setEditEmail(false);
                    var myHeaders = new Headers();
                    myHeaders.append(
                      "X-CSRF-Token",
                      String(
                        JSON.parse(window.localStorage.getItem("user-info"))
                          .csrf_token
                      )
                    );
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                      pass: [{ existing: oldPass }],
                      mail: { value: email },
                    });

                    var requestOptions = {
                      method: "PATCH",
                      headers: myHeaders,
                      body: raw,
                      redirect: "follow",
                      credentials: "include",
                    };

                    fetch(
                      process.env.REACT_APP_LLOM_API_HOST +"/user/"+JSON.parse(window.localStorage.getItem("user-info")).current_user.uid+"?_format=json",
                      requestOptions
                    )
                      .then((response) => response.text())
                      .then((result) => {
                        if (JSON.parse(result).message) {
                          setErrorMessage("An error occured");
                        }
                      })
                      .catch((error) => console.log("error", error));
                  }}
                >
                  Save
                </div>
              </div>
            ) : (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">E-mail</h4>
                  <h1 className="motto">{email}</h1>
                </div>
                <div
                  className="edit-button inline"
                  onClick={() => setEditEmail(true)}
                >
                  Edit
                </div>
              </div>
            )}
            {/*editID ? (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">Some ID</h4>
                  <input
                    className="login-form"
                    onChange={(e) => setID(e.target.value)}
                  />
                </div>
                <div
                  className="inline edit-button"
                  onClick={() => setEditID(false)}
                >
                  Save
                </div>
              </div>
            ) : (
              <div>
                <div className="inline margin">
                  <h4 className="text-label">Some ID</h4>
                  <h1 className="motto">{ID}</h1>
                </div>
                <div
                  className="inline edit-button"
                  onClick={() => setEditID(true)}
                >
                  Edit
                </div>
              </div>
            )*/}
          </div>
          {saveAll ? <div className="inline margin">
            <h4 className="text-label">Enter Password and Save All</h4>
            <input
              className="login-form"
              onChange={(e) => setOldPass(e.target.value)}
              type="password"
            />
            <Button text="SAVE" background="yellow" link="/profile" onClick={() => {
              var myHeaders = new Headers();
              myHeaders.append(
                "X-CSRF-Token",
                String(
                  JSON.parse(window.localStorage.getItem("user-info"))
                    .csrf_token
                )
              );
              myHeaders.append("Content-Type", "application/json");
              
              var raw = JSON.stringify({
                name: [{ value: name }],
                mail: [{ value: email }],
                pass: [{ existing: oldPass }]
              });

              var requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
                credentials: "include",
              };

              fetch(
                process.env.REACT_APP_LLOM_API_HOST +"/user/"+JSON.parse(window.localStorage.getItem("user-info")).current_user.uid+"?_format=json",
                requestOptions
              )
                .then((response) => response.text())
                .then((result) => {
                  if (JSON.parse(result).message) {
                    setErrorMessage("An error occured");
                  }
                })
                .catch((error) => console.log("error", error));
                  
              }}/>
                </div>:<></>}
          <div className="d-flex justify-content-center p-2">
            {editEmail ||  editName || editPassword ? (
              !saveAll&&<Button text="SAVE" background="yellow" onClick={() => setSaveAll(true)}/>
            ) : (
              <Button text="SAVE" background="grey" />
            )}
          </div>
        </div>

        <div>
          <Menu />
        </div>

        <Footer />
      </div>
    );
  }
}

export default EditProfile;
