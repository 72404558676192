import React from "react";
import "./../styles/App.scss";
import coFunded from "./../img/co-funded.png";

function Footer(props) {
  return (
    <footer>
      <div className="row">
        <div className="col">
          <img 
            className="img-fluid"
            src={coFunded} 
            alt="Co-funded by the Erasmus+ Programme of the European Union" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
