import React from "react";
import "./../../styles/App.scss";
import Button from "../Button";
import Footer from "./../Footer";
import logo from "./../../img/LLOM_logo.svg";
import MaterialIcon from 'material-icons-react';

function LoginRegisterForgotPw(props) {
  return (
    <div className="intro">
      <span style={{cursor:"pointer",left:"0",top:"10px",position:"absolute"}} onClick={() => props.history.goBack()}><MaterialIcon icon="arrow_left" size="50"/></span>
      <span style={{cursor:"pointer",right:"0",top:"10px",position:'absolute'}} onClick={() => props.history.goForward()}><MaterialIcon icon="arrow_right" size="50"/></span>
      
      <img className="logo-front" src={logo} alt="logo" />
      <h1 className="motto">
        Learning Languages Online in the age of Mobility
      </h1>
      <div className="start-buttongroup">
        <span>Already a member?</span>
        <Button background="green" text="LOG IN" link="/login-form"></Button>
        <span>
          <div className="start-buttongroup">Not registered yet?</div>
        </span>
        <Button background="yellow" text="REGISTER" link="/register"></Button>
      </div>
      <Footer />
    </div>
  );
}

export default LoginRegisterForgotPw;
