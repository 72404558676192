import React from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (JSON.parse(window.localStorage.getItem("user-info")) &&
          props.location.pathname !== "/login-form" &&
          props.location.pathname !== "/reset-password" &&
          props.location.pathname !== "/register") {
          // If user is authenticated and tries to access
          // any page except the login, password and register pages
          return <Component {...props} />;
        } else if (JSON.parse(window.localStorage.getItem("user-info")) &&
          (props.location.pathname === "/login-form" ||
          props.location.pathname === "/reset-password" ||
          props.location.pathname === "/register")) {
          // If user is authenticated and tries to access
          // either one of login, password and register pages
          return (
            <Redirect
              to={{
                pathname: "/registration-landing",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else if (!JSON.parse(window.localStorage.getItem("user-info")) &&
          (props.location.pathname === "/login-form" ||
          props.location.pathname === "/reset-password" ||
          props.location.pathname === "/register")) {
          // If user is not authenticated and tries to access
          // either one of login, password and register pages
          return <Component {...props} />;
        } else { 
          return (
            <Redirect
              to={{
                pathname: "/intro",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};