import React, { useState, useEffect } from "react";
import "./../../styles/App.scss";
import Header from './../Header';
import Menu from "../Menu";
import {Link} from 'react-router-dom'
import Loader from "react-loader-spinner";
import { ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CircularButtons from './../CircularButtons'
import { useHistory } from 'react-router-dom';

function Game({
  match: {
    params: { name },
  },
}) {
  let params = useLocation();
  const [data, setData] = useState([]);
  const [loaded,setLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    var myHeaders = new Headers();
    const token = JSON.parse(window.localStorage.getItem("user-info")).csrf_token;
    myHeaders.append(
      "X-CSRF-Token",
      String(
        token
      )
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      credentials: "include",
      myHeaders
    };

    fetch(
      process.env.REACT_APP_LLOM_API_HOST +"/rest-all-assignments?field_llom_level_target_id=" +
      window.localStorage.getItem("level")+"&field_llom_topic_target_id=" +
        encodeURIComponent(name) +
        "&field_language_target_id=" +
        window.localStorage.getItem("lang"),
        requestOptions
    )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      setLoaded(true);
      if (result.message !== ""){
        setLoaded(true);
        setData(result);
      }
    })
    .catch((error) => console.log("error", error));
  }, [name]);
  if (!loaded) {
    return (
      <div className="App">
        <Loader
          className="spinner"
          type="TailSpin"
          color="#3986df"
          height={70}
          width={70}
        />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Header 
          history={history} 
          title_above={JSON.parse(window.localStorage.getItem("user-info")).current_user.name} />
        <CircularButtons topic={params.search.split("=")[1]} />
        <div style={{margin:"0 14% 0 14%"}}>
          <ListGroup className="d-flex justify-content-center m-4" >
            {data.length === 0 ? <>There are currently no assignments for this category in {window.localStorage.getItem("lang")} language. Please pick another category or come back another time.</>:data.map((row) => (
              <Link className="link" to={"/quiz/"+row.nid+"?topic="+params.search.split("=")[1]}>
                <ListGroup.Item className="listgroup-item">{row.title}</ListGroup.Item>
              </Link>
            ))}
          </ListGroup>
        </div>

        <div>
          <Menu />
        </div>
      </div>
    );
  }
}

export default Game;
