import React from "react";
import "./styles/App.scss";
import Routes from "./components/Routes";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <>
      <Routes />

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="consent-cookie-policy"
        style={{ background: "grey" }}
        buttonStyle={{ color: "white", background: "black", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          alert("You have accepted the use of cookies");
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
}

export default App;
