import React from "react";
import { Link } from "react-router-dom";

function Menu() {
  return (
    <div>
      <div className="nav-container">
      <nav>
        <input id="nav" type="checkbox" />

        <label htmlFor="nav">
          <b>
            <i></i>
            <i></i>
            <i></i>
          </b>
        </label>

        <menu>
          <li>
            <Link to="/">PLAY</Link>
          </li>
          <li>
            <a href="https://uni-foundation.eu/project/llom/">ABOUT LLOM</a>
          </li>
          <li>
            <Link to="/achievements">ACHIEVEMENTS</Link>
          </li>
          <li>
            <Link to="/profile">PROFILE</Link>
          </li>
          <li>
            <Link to="/edit">SETTINGS</Link>
          </li>
          <li>
            <Link
              to="/intro"
              onClick={() => {
                var myHeaders = new Headers();
                myHeaders.append(
                  "X-CSRF-Token",
                  String(
                    JSON.parse(window.localStorage.getItem("user-info"))
                      .csrf_token
                  )
                );
                myHeaders.append("withCredentials", true);
                myHeaders.append("Access-Control-Allow-Origin", "*");
                myHeaders.append("Access-Control-Expose-Headers", "*");
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  redirect: "follow",
                  credentials: "include",
                };

                fetch(
                  process.env.REACT_APP_LLOM_API_HOST +"/user/logout?_format=json&token=" +
                    String(
                      JSON.parse(window.localStorage.getItem("user-info"))
                        .logout_token
                    ),
                  requestOptions
                )
                  .then((response) => response.text())
                  .then((result) => {
                    window.localStorage.removeItem("user-info");
                  })
                  .catch((error) => console.log("error", error));
              }}
            >
              LOG OUT
            </Link>
          </li>
        </menu>
      </nav>
      </div>
    </div>
  );
}
export default Menu;
