import React, { useEffect, useState } from "react";
import "./../styles/App.scss";
import Header from './Header';
import Footer from './Footer';
import Loader from "react-loader-spinner";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import CircularButtons from "./CircularButtons";

function LandingPage(props) {
  const [data, setData] = useState(Array([]));
  useEffect(() => {
 
    var myHeaders = new Headers();
    myHeaders.append(
      "X-CSRF-Token",
      String(
        JSON.parse(window.localStorage.getItem("user-info"))
          .csrf_token
      )
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    };
    fetch(
      process.env.REACT_APP_LLOM_API_HOST +"/rest-taxonomy/topic",
      requestOptions
    )
    .then((response) => {
      return response.json()
    })
    .then((result) => {
      setData(result)
    })
    .catch((error) => console.log("error", error));
  }, []);

  if (data.length === 0) {
    return (
      <div className="App">
        <Loader
          className="spinner"
          type="TailSpin"
          color="#3986df"
          height={70}
          width={70}
        />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Header history={props.history} title_above="Hello" title_below={JSON.parse(window.localStorage.getItem("user-info"))
                  .current_user.name+"!"}/>
        <CircularButtons topic="?"/>
        <h3 className="moto">Which topic would you like to practice today?</h3>
        <div className="main">
          <div className="choices">
            {data.slice(0, data.length/2).map((row, index) => (
              <div className="choice" key={index}>
                <Link
                  className="link"
                  to={`/game/${row.name}?topic=${row.field_topic_icon}`}
                >
                 <MaterialIcon icon={row.field_topic_icon} size={20} />
                  {row.name}
                </Link>
              </div>
            ))}
          </div>
          <div className="choices">
            {data.slice(data.length/2, data.length).map((row, index) => (
              <div className="choice" key={index}>
                <Link
                  className="link"
                  to={`/game/${row.name}?topic=${row.field_topic_icon}`}
                  >
                  <MaterialIcon icon={row.field_topic_icon} size={20} />
                  {row.name}
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div>
          <Menu />
        </div>
        
        <Footer />
      </div>
    );
  }
}

export default LandingPage;