import React, { useState,useEffect } from "react";
import "./../../styles/App.scss";
import Select from "react-select";
import Button from "../Button";
import Footer from "./../Footer";
import Header from './../Header';
import Menu from "../Menu";

function RegistrationLandingPage(props) {
  
  const [lang, setLang] = useState();
  const [code, setCode] = useState();
  const [level, setLevel] = useState();
  const [langList, setLangList] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  /*const continueButtonSubmit = event => {
    if(lang && level){
      window.localStorage.setItem('lang',lang)
      window.localStorage.setItem('code',code)
      window.localStorage.setItem('level',level)
      window.location.replace("/");
    } else {
      setErrorMessage("You must select language and level!")
    }
  }*/

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-CSRF-Token",
      String(
        JSON.parse(window.localStorage.getItem("user-info"))
          .csrf_token
      )
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      credentials: "include",
      headers: myHeaders,
    };
    fetch(
      process.env.REACT_APP_LLOM_API_HOST +"/rest-taxonomy/language",
      requestOptions
    )
    .then((response) => response.json())
    .then((result) => setLangList(result))
    .catch((error) => console.log("error", error));
  }, []);

  const lang_options = langList && langList.map(i=>{
    return(
    {
      code: i.field_country_code,
      lang: i.name,
      label: (
        <div>
          {i.name}
          <img className="lang-icon" src={"https://purecatamphetamine.github.io/country-flag-icons/3x2/"+i.field_country_code+".svg"} alt={i.field_country_code}/>
        </div>
      )
    })
  });

  const level_options = [
    { value: "A1", label: <div>A1 (Beginner)</div> },
    { value: "A2", label: <div>A2 (Elementary)</div> },
    { value: "B1", label: <div>B1 (Intermediate)</div> },
    { value: "B2", label: <div>B2 (Upper Intermediate)</div> },
    { value: "C1", label: <div>C1 (Advanced)</div> },
    { value: "C2", label: <div>C2 (Master)</div> },
  ];

  const onSelectLangChange = selected => {
    setCode(selected.code);
    setLang(selected.lang);
  };

  const onSelectLevelChange = selected => {
    setLevel(selected.value);
  };

  return (
    <div className="App">
      <Header 
        history={props.history} 
        title_above="Hello" 
        title_below={JSON.parse(window.localStorage.getItem("user-info")).current_user.name+"!"} />

      { errorMessage &&
        <div class="col-6 mx-auto mt-4">
          <div class="alert alert-danger" role="alert">
            { errorMessage }
          </div>
        </div> 
      }

      <h3 className="moto margin">
        Which language you would like to learn or practice?
      </h3>

      <Select
        className="select margin inline-half"
        onChange={onSelectLangChange}
        options={lang_options}
      />
      <h3 className="moto margin">Choose your level:</h3>
      <Select
        className="select margin inline-half"
        onChange={onSelectLevelChange}
        options={level_options}
      />
      <div className="d-flex justify-content-center p-2 mt-4">
        <Button background="blue" text="CONTINUE" link="" onClick={() => {
          if (lang && level){
            window.localStorage.setItem('lang',lang)
            window.localStorage.setItem('code',code)
            window.localStorage.setItem('level',level)
            window.location.replace("/");
          } else {
            setErrorMessage("You must select language and level!")
          }
        }} />
      </div>

      <Footer />

      <div>
        <Menu />
      </div>
    </div>
  );
}

export default RegistrationLandingPage;
