import React from "react";
import "./../../styles/App.scss";
import Button from "../Button";
import Footer from "./../Footer";
import logo from "./../../img/LLOM_logo.svg";
import {Link} from 'react-router-dom'
import MaterialIcon from 'material-icons-react'

function ResetPassword(props) {
  //const [email,setEmail]=useState()
  /*const history = useHistory();*/
  return (
    <div className="intro">
      <span style={{cursor:"pointer",left:"0",top:"10px",position:"absolute"}} onClick={() => props.history.goBack()}><MaterialIcon icon="arrow_left" size="50"/></span>
      <span style={{cursor:"pointer",right:"0",top:"10px",position:'absolute'}}onClick={() => props.history.goForward()}><MaterialIcon icon="arrow_right" size="50"/></span>
      
      <Link to="/"><img className="logo-front" src={logo} alt="logo"/></Link>
      <h1 className="motto">Reset password</h1>
      <div className="line-green" />
      {/*<div className="text-label">E-mail address</div>
      <input className="login-form" onChange={e=>setEmail(e.target.value)}/>
      <div className="text-label-resetpass">
        {We will send a link to your email address. Click on it and will be able
        to reset your password.
        }
        
      </div>
      */}
      <Button background="blue" text="RESET PASSWORD" onClick={() => {
        window.location.replace(process.env.REACT_APP_LLOM_API_HOST+"/user/password")
        /*
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({"mail":String(email)});
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch(process.env.REACT_APP_LLOM_API_HOST +"/user/lost-password?_format=json", requestOptions)
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
          */
         
      }}>

      </Button>

      <Footer />
    </div>
  );
}

export default ResetPassword;
