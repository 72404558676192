import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "./../img/LLOM_logo.svg";
import PropTypes from 'prop-types'
import "./../styles/App.scss";
import MaterialIcon from 'material-icons-react';

function Header(props) {
  return (
    <Fragment>
      
      <span style={{cursor: "pointer", left:"0", top:"10px", position:"absolute"}} onClick={() => props.history.goBack()}>
        <MaterialIcon icon="arrow_left" size="50"/>
      </span>
      
      <div className="inline-half">
      
        <div style={{position:"relative"}}>
          <h3 className="motto header-title">
            {props.title_above}
          </h3>
          <div className="line-yellow" />
          <h3 style={{float: "left", marginBottom: "20px"}}>{props.title_below}</h3></div>
      </div>
      <Link to="/">
        <div className="inline">
          <img
            className="header-logo"
            alt="logo"
            src={logo}
          />
        </div>
      </Link>
      <span style={{cursor: "pointer", right: "0", top: "10px", position: 'absolute'}} onClick={() => props.history.goForward()}>
        <MaterialIcon icon="arrow_right" size="50"/>
      </span>
      
    </Fragment>
  );
}

export default Header;
Header.propTypes = {
	/**
	 * The header title above the yellow line.
	 */
	title_above: PropTypes.string,
  /**
   * The header title above the below line.
   */
  title_below: PropTypes.string
};